<template>
  <div class="form-group">
    <div class="d-flex justify-content-between">
      <label :for="inputId" v-if="label" class="input-label">
        {{ label }}
      </label>
      <Popper
        arrow
        v-if="$slots['info-popover']"
        placement="top"
        openDelay="100"
      >
        <i class="far fa-info-circle"></i>
        <template v-slot:content>
          <slot name="info-popover"></slot>
        </template>
      </Popper>
    </div>
    <div>
      <div class="input-group" :class="{ 'is-invalid': errorMessage }">
        <div class="input-group-text" v-if="$slots['prepend']">
          <slot name="prepend"></slot>
        </div>
        <input
          class="form-control"
          :class="{ 'is-invalid': errorMessage }"
          :autocomplete="autocomplete"
          :disabled="disabled"
          v-bind="$attrs"
          :name="name"
          :id="inputId"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          @input="handleChange"
          @blur="handleBlur"
        />
        <div class="input-group-text" v-if="$slots['append']">
          <slot name="append"></slot>
        </div>
      </div>
      <div class="invalid-feedback" v-show="showErrors && errorMessage">
        <span>
          {{ errorMessage}}
        </span>
      </div>
      <div class="invalid-feedback" v-show="!errorMessage && infoError">
        <span>
          {{ infoError }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRef, computed } from 'vue';
import { useField, defineRule, configure, Field, ErrorMessage } from 'vee-validate';
import {
  required,
  email,
  min,
  min_value,
  max,
  max_value,
  is,
  regex,
} from '@vee-validate/rules';
import Popper from "vue3-popper";
import i18n from '@/utils/localization';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  name: {
    type: String,
    default: () => Math.random().toString(36).substring(7),
  },
  label: {
    type: String,
    default: '',
  },
  infoError: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  rules: {
    type: [String, Object],
    default: '',
  },
  autocomplete: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
});

// const model = defineModel();

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(
  name,
  props.rules,
  {
    initialValue: props.modelValue,
    modelPropName: 'modelValue',
    syncVModel: true,
    label: props.label,
  }
);

function onInputChanged(e) {
  handleChange(e.target.value)
}

const inputName = computed(() => props.name || props.label);
const inputId = computed(() => inputName.value.toLowerCase().split(' ').join('-'));

const isValidUrl = enteredUrl => {
  if (!enteredUrl) return false;
  try {
    const url = new URL(enteredUrl);
    return (
      url.protocol === 'http:' ||
      url.protocol === 'https:' ||
      url.protocol === 'mailto:'
    );
  } catch (_) {
    return false;
  }
};

defineRule('email', email);
defineRule('required', required);
defineRule('min', min);
defineRule('min_value', min_value);
defineRule('max', max);
defineRule('max_value', max_value);
defineRule('regex', regex);
defineRule('is', (value, [target]) => {
  if (value === target) {
    return true;
  } else {
    return i18n.t('GENERAL.ERROR.INPUT_IS_INCORRECT');
  }
});
defineRule('password', (value, [target]) => {
  if (value === target) {
    return true;
  } else {
    return i18n.t('GENERAL.ERROR.PASSWORD_CONFIRMATION_DOES_NOT_MATCH');
  }
});
defineRule('url', (value) => {
  if (value && isValidUrl(value)) {
    return true;
  } else {
    return i18n.t('GENERAL.ERROR.NOT_A_VALID_URL');
  }
});

defineRule('min_currency', (value, [min, amount]) => {
  if (value >= parseFloat(min)) {
    return true;
  } else {
    return i18n.t('GENERAL.ERROR.THE_LABEL_FIELD_MUST_BE_AMOUNT_OR_MORE', {
      inputName,
      amount,
    });
  }
});
</script>
  
<style scoped>
.input-label {
  text-transform: capitalize;
}
</style>
  